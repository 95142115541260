<template>
  <div style="position: relative">
    <div
      class="card p-3"
      style="
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 3;
        min-width: 8rem;
      "
    >
      <b-field expanded :label="t('exam.timeToEnd')" label-position="on-border">
        <p>{{ formattedTimeLeft }}</p>
      </b-field>

      <!-- <div v-if="images" class="mb-3">
        <p>Obrazy:</p>
        <b-field v-for="item in images" :key="item.index" class="mb-1">
          <b-radio-button
            expanded
            :native-value="item"
            size="is-small"
            v-model="currentLayer"
            >{{ item }}</b-radio-button
          >
        </b-field>
      </div> -->
      <b-field expanded :label="t('deepzoom.image')" label-position="on-border">
        <b-select
          expanded
          size="is-small"
          :placeholder="t('common.choose')"
          v-model="currentImage"
          @input="imageChanged"
        >
          <option v-for="item in images" :key="item.id" :value="item">
            {{ item.id }}
          </option>
        </b-select>
        
      </b-field>
      <div v-if="currentImage" class="mb-3">
        <b-field
          v-for="item in currentImage.layers"
          :key="item.index"
          class="mb-1"
        >
          <b-radio-button
            expanded
            :native-value="item"
            size="is-small"
            v-model="currentLayer"
            >{{ item.title }}</b-radio-button
          >
        </b-field>
      </div>
      <b-button
        @click="stopExam"
        type="is-danger"
        expanded
        size="is-small"
        icon-left="save"
        :disabled="isEnded"
      >
        {{t('exam.stop')}}</b-button
      >
      <b-button
      v-if="openSeaDragon"
        icon-left="pencil"
        @click="switchEditor"
        size="is-small"
        expanded
        :type="{ 'is-info': editorMode, 'is-primary': !editorMode }"
      >
        <span v-if="editorMode">{{t('openSea.readMode')}}</span>
        <span v-else>{{t('openSea.editMode')}}</span>
      </b-button>
      <div v-if="currentObjects">
        <b-field v-for="item in currentObjects.objects" :key="item.id">
          <ExamImageObject
            :object="item"
            @deleteObject="deleteObjectExam"
            @saveChanges="saveChangesObjectExam"
            @showObject="showObject"
          />
        </b-field>
      </div>
    </div>
    <!-- OPENSEADRAGON VIEWER -->
    <div
      id="openseadragon"
      style="width: 100%; height: calc(100vh - 50px)"
    ></div>
    <div style="display: none">
      <button id="mockupButton0"></button>
      <button id="mockupButton1"></button>
    </div>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
import ExamImageObject from "./ExamImageObject";
import { fabric } from "fabric";
import { Action } from "../../../store/config_actions";
import moment from "moment";
import { SERVER } from '@/store/config_apis'

export default {
  name: "ExamImage",
  mixins: [CommonMixins],
  components: {
    ExamImageObject,
  },

  data: function () {
    return {
      userExam: {},
      openSeaDragon: null,
      fabrics: null,
      zoomValue: 1,
      editorMode: false,
      imageObjects: [],
      newObject: {
        image: "",
        objects: [],
        canvasJson: "",
      },
      index: 0,
      images: [],
      currentImage: null,
      currentLayer: null,
      isLoading: false,
      sendData: null,
      examInterval: null,
      isStarted: false,
      timeLeft: 0,
      timeUser: 0,
      isEnded: false,
      timeOver: false,
    };
  },

  props: { idUserExam: { type: String, required: true } },

  watch: {
    currentLayer(val) {
      if (this.currentImage) {
        let page = this.currentImage.layers.indexOf(val);

        if (page > -1) {
          this.goToLayer(page);
        }
      }
    },

    timeUser() {
      if (this.userExam.maxTime * 60 - this.timeUser <= 0) {
        this.timeOver = true;
        this.examEnded();
      }
    },
  },

  mounted() {
    //this.openSeaInitialize();
    //this.getImages();
    this.getUserExam();
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },
    isAdmin() {
      return this.session.roles.includes("Admin");
    },

    currentObjects() {
      if(this.currentLayer){
      return this.imageObjects.find((imO) => imO.image === this.currentLayer.id);
      }else return []
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;

      const timeMinutes = Math.floor(timeLeft / 60);
      const hours = Math.floor(timeMinutes / 60);
      let minutes = timeMinutes % 60;
      let seconds = timeLeft % 60;

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    },
  },

  methods: {
    layerChanged(value) {
      this.openSeaInitialize(value);
    },

    setImages() {
      if (this.images.length > 0) {
        let imgs = [];

        this.images.forEach((img) => {
          imgs.push(`${SERVER}/api/Images/file/${img}.xml`);
          let imageObj = this.imageObjects.find(
            (object) => object.image == img
          );
          if (!imageObj) {
            let newObject = {
              image: img,
              objects: [],
              canvasJson: "",
            };
            this.imageObjects.push(newObject);
          }
        });

        this.currentLayer = this.images[0];

        this.openSeaInitialize(imgs);
      }
    },

     imageChanged(value) {
      this.currentImage = value;
      if (value.layers.length > 0) {
        let imgs = [];

        value.layers.forEach((img) => {
          imgs.push(`${SERVER}/api/DeepZoomConfig/Image/${value.id}/${img.id}.xml`)

          let imageObj = this.imageObjects.find(
            (object) => object.image == img.id
          );
          if (!imageObj) {
            let newObject = {
              image: img.id,
              objects: [],
              canvasJson: "",
            };
            this.imageObjects.push(newObject);
          }
        });

        this.currentLayer = value.layers[0];
        this.openSeaInitialize(imgs);
      }
    },

    goToLayer(index) {
      if (this.openSeaDragon) {
        this.canvas.remove(...this.canvas.getObjects());
        this.openSeaDragon.goToPage(index);
        this.addCurrentObjectsToCanvas();
      }
    },

    switchEditor() {
      this.editorMode = !this.editorMode;
      this.setEditMode();
    },

    setEditMode() {
      if (this.editorMode) {
        this.canvas.isDrawingMode = true;
        this.openSeaDragon.setMouseNavEnabled(false);
        this.openSeaDragon.outerTracker.setTracking(false);
      } else {
        this.canvas.isDrawingMode = false;
        this.openSeaDragon.setMouseNavEnabled(true);
        this.openSeaDragon.outerTracker.setTracking(true);
      }
    },

    // getImages() {
    //   this.isLoading = true;
    //   this.$store
    //     .dispatch(Action.IMAGES_GET_LIST)
    //     .then((response) => {
    //       this.isLoading = false;
    //       this.images = response;
    //     })
    //     .catch((error) => {
    //       this.mDangerSnack(error);
    //       this.isLoading = false;
    //     });
    // },

    /**
     * OPENSEA
     * Initialize OpenSeaDragon component and configure events.
     */
    openSeaInitialize(image) {
      if (this.openSeaDragon === null) {
        // OpenSeaDragon init.
        // eslint-disable-next-line no-undef
        this.openSeaDragon = OpenSeadragon({
          id: "openseadragon",
          tileSources: image,
          maxZoomLevel: 7,
          showNavigationControl: false,
          showNavigator: true,
          sequenceMode: true,
          preserveViewport: true,
          nextButton: "mockupButton0",
          previousButton: "mockupButton1",
        });
      } else {
        this.canvas.remove(...this.canvas.getObjects());
        this.openSeaDragon.open(image);
      }

      // Initialize FabricsJS overlay
      this.fabrics = this.openSeaDragon.fabricjsOverlay({ scale: 1000 });

      // Get canvas instance
      this.canvas = this.fabrics.fabricCanvas();

      this.setEditMode();

      // Paint it red!
      this.canvas.freeDrawingBrush.color = "red";

      // Configure on path added event.
      this.canvas.on("path:created", this.pathCreated);
    },

    /**
     * Event on SVG path created.
     */
    pathCreated(item) {
      var newPath = item.path;
      var arrayPath = newPath.path;
      var xStart = arrayPath[0][1];
      var ystart = arrayPath[0][2];
      var length = arrayPath.length;
      newPath.path[length - 1][1] = xStart;
      newPath.path[length - 1][2] = ystart;

      var text = new fabric.IText(this.t('exam.shapeTitle'), {
        left: xStart,
        top: ystart,
        fontSize: 12,
        fontFamily: "Verdana",
        fill: "black",
        visible: true,
      });

      this.canvas.remove(item.path);
      this.canvas.add(newPath);
      if (this.currentObjects) {
        this.currentObjects.objects.push({
          id: this.index,
          label: text.text,
          svg: newPath.toSVG(),
          path: newPath,
          orginal: item,
          text: text,
          x: xStart,
          y: ystart,
        });

        this.currentObjects.canvasJson = JSON.stringify(this.canvas.toJSON());
      }

      this.canvas.add(text).setActiveObject(text);
      text.enterEditing();
      text.hiddenTextarea.focus();
      this.index = this.index + 1;
    },

    deleteObjectExam(object) {
      if (object) {
        let baseObject = this.currentObjects.objects.find(
          (q) => q.id === object.id
        );
        if (baseObject) {
          this.canvas.remove(baseObject.path);
          this.canvas.remove(baseObject.text);
          this.currentObjects.objects.splice(
            this.currentObjects.objects.indexOf(baseObject),
            1
          );
          this.currentObjects.canvasJson = JSON.stringify(this.canvas.toJSON());
        }
      }
    },

    addCurrentObjectsToCanvas() {
      this.currentObjects.objects.forEach((element) => {
        this.canvas.add(element.path);
        this.canvas.add(element.text);
      });
    },

    saveChangesObjectExam(object) {
      if (object) {
        this.canvas.renderAll();
        this.currentObjects.canvasJson = JSON.stringify(this.canvas.toJSON());
      }
    },

    imagePanTo: function (x, y, height) {
      //TODO: scale x,y to openseadragon
      var imageScale = 1500;

      var targetX = x / imageScale;
      var targetY = (y - 50) / imageScale;
      var targetZoom = imageScale / height / 10;

      // eslint-disable-next-line no-undef
      this.openSeaDragon.viewport.panTo(
        // eslint-disable-next-line no-undef
        new OpenSeadragon.Point(targetX, targetY)
      );
      this.openSeaDragon.viewport.zoomTo(targetZoom);
    },

    showObject(object) {
      if (object) {
        let baseObject = this.currentObjects.objects.find(
          (q) => q.id === object.id
        );
        if (baseObject) {
          this.imagePanTo(
            baseObject.path.left,
            baseObject.path.top,
            baseObject.path.height
          );
        }
      }
    },

    /** Start exam */
    examStart() {
      this.isStarted = true;
      this.examInterval = setInterval(() => {
        let difference = moment(this.userExam.created).diff(
          moment(),
          "seconds"
        );
        this.timeLeft = this.userExam.maxTime * 60 + difference;
        this.timeUser = difference * -1;
      }, 1000);
    },

    /** Stop exam */
    stopExam() {
      if (this.userExam) {
        this.userExam.shapeData = [];
        this.imageObjects.forEach((img) => {
          this.userExam.shapeData.push({
            image: img.image,
            shapes: img.canvasJson,
          });
        });

        this.userExam.isFinished = true;
        this.updateUserExam();
      }
    },

    /** Update UserExam */
    updateUserExam() {
      this.userExam.time = this.timeUser;
      this.$store
        .dispatch(Action.USEREXAM_UPDATE, this.userExam)
        .then((payload) => {
          this.mSuccessSnack(this.t('exam.sended'));
          if (payload.isFinished) {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },

    /** Get UserExam */
    getUserExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_EXAM_BY_ID, this.idUserExam)
        .then((payload) => {
          if (!payload.isFinished) {
            this.userExam = payload;
            //this.images = payload.images;
            //this.setImages();
            this.isLoading = false;
            this.getDeepZoomConfigForExamsImages(payload.images)            
          } else {
            this.isEnded = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /** Get current deepZooomConfig */
    getDeepZoomConfigForExamsImages(idImages) {
      this.isLoading = true;
      let send = {images: idImages}
      this.$store
        .dispatch(Action.DEEPZOOM_CONFIG_GROUP,send)
        .then((payload) => {
          this.images = payload.images;
          this.examStart();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },
  },
};
</script>

<style scoped>
</style>