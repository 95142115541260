<template>
  <div>
    <b-field>
      <b-icon icon="label"></b-icon>
      <template v-if="editMode">
        <b-input v-model="object.text.text" type="textarea" maxlength="150" size="is-small"></b-input>
      </template>
      <template v-else>
        <p>{{ object.text.text }}</p>
      </template>
    </b-field>
    <div class="buttons has-addons is-right">
      <b-button icon-left="eye" type="is-success is-light" @click="showObject" size="is-small">
      </b-button>
      <b-button
        :class="{ 'is-success': editMode, 'is-primary': !editMode }"
        @click="changeObject"
        size="is-small"
      >
        <b-icon v-if="editMode" icon="file-check" size="is-small"></b-icon>
        <b-icon v-else icon="file-edit" size="is-small"></b-icon>
      </b-button>
      <b-button
        type="is-danger"
        :disabled="editMode"
        icon-left="delete"
        @click="deleteObject"
        size="is-small"
      >
      </b-button>
    </div>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";

export default {
  name: "ExamImageObject",
  mixins: [CommonMixins],

  props: {
    object: { type: Object, required: true },
  },

  data: function () {
    return {
      editMode: false,
      localObject: null,
    };
  },

  mounted() {
    this.localObject = this.object;
  },

  methods: {
    deleteObject() {
      this.$emit("deleteObject", this.localObject);
    },

    changeObject() {
      this.editMode = !this.editMode;
      if (!this.editMode) {
        this.$emit("saveChanges", this.localObject);
      }
    },

    showObject() {
      this.$emit("showObject", this.localObject);
    },
  },
};
</script>